define('tunespeak-ember/components/contest-actions/link-click', ['exports', 'tunespeak-ember/mixins/components/campaign-action'], function (exports, _campaignAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_campaignAction.default, {
    classNames: ['link-click'],
    iconClass: Ember.computed('model.content.icon', function () {
      return this.get('model.content.icon') ? this.get('model.content.icon').dasherize() : 'icon-link';
    }),
    isInstagram: Ember.computed.equal('model.content.icon', 'icon_instagram'),
    isDaysies: Ember.computed.equal('model.content.icon', 'icon_daysies'),
    btnLabel: Ember.computed('model.content.btn_label', function () {
      return this.get('model.content.btn_label') || 'VISIT';
    }),
    iframeModalOpen: false,

    actions: {
      linkClick: function linkClick() {
        var _this = this;

        if (!this.get('userAction.completed')) {
          this.set('userAction.completed', true);
          var click = this.get('store').createRecord('link-click', {
            promotionId: this.get('promotionId'),
            contestId: this.get('contest.id'),
            contestActionId: this.get('model.id')
          });
          click.save().then(function () {
            _this.get('googleTracking')('link-click', {
              category: 'campaign-action',
              label: _this.get('title')
            });
          });
        }
        if (this.get('model.content.iframe')) {
          this.set('iframeModalOpen', true);
        } else {
          window.open(this.get('model.content.url'));
        }
      },

      closeIframeModal: function closeIframeModal() {
        this.set('iframeModalOpen', false);
      }
    }
  });
});